import axios from './axios';
import {
  setAuthorizationHeader,
  setFormDataHeader,
  clearFormDataHeader,
} from './headers';

export const getAllResults = async (
  token,
  page,
  limit,
  userId,
  faculty,
  role,
  department,
  testDate
) => {
  setAuthorizationHeader(token);
  try {
    const params = {
      page,
      limit,
      ...(userId && { userId }),
      ...(faculty && { faculty }),
      ...(role && { role }),
      ...(department && { department }),
      ...(testDate && { testDate }),
    };

    const response = await axios.get(`/results`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const exportToExcelApi = async (token, page, limit) => {
  setAuthorizationHeader(token);
  try {
    const params = {
      page,
      limit,
    };

    const response = await axios.get(`/results`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllQuestions = async (
  token,
  page,
  limit,
  _id,
  faculty,
  role,
  department
) => {
  setAuthorizationHeader(token);
  try {
    const params = {
      page,
      limit,
      ...(_id && { _id }),
      ...(faculty && { faculty }),
      ...(role && { role }),
      ...(department && { department }),
    };

    const response = await axios.get(`/questions`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const addQuestion = async (token, data) => {
  setAuthorizationHeader(token);
  try {
    const response = await axios.post(`/questions`, data);
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    clearFormDataHeader();
  }
};
export const addBulkQuestion = async (token, data) => {
  setAuthorizationHeader(token);
  try {
    const response = await axios.post(`/questions/bulk`, data);
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    clearFormDataHeader();
  }
};
export const updateQuestion = async (token, data, id) => {
  setAuthorizationHeader(token);
  try {
    const response = await axios.patch(`/questions/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    clearFormDataHeader();
  }
};
export const deleteQuestion = async (token, id) => {
  setAuthorizationHeader(token);
  try {
    const response = await axios.delete(`/questions/${id}`, {});
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    clearFormDataHeader();
  }
};

export const updateExamDetails = async (token, data) => {
  setAuthorizationHeader(token);
  try {
    const response = await axios.post(`/admin/update-many-exam-details`, data);
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    clearFormDataHeader();
  }
};
