import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllResults, exportToExcelApi } from '../../api/examApi';
import { useAuth } from '../../context/AuthContext';
import ReusableTable from '../../components/table/ReusableTable';
// import TableOption from '../../components/table/TableOption';
import Loader from '../../components/loader/Loader';
import EmptyTable from '../../components/table/EmptyTable';
import SelectField from '../../components/control/SelectField';
import FormatDate from '../../components/table/FormatDate';
import FilterSearch from '../../components/filter/FilterSearch';
import InputField from '../../components/control/InputField';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
const Results = () => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const token = localStorage.getItem('token');

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [userId, setUserId] = useState(null);
  const [faculty, setFaculty] = useState(null);
  const [department, setDepartment] = useState(null);
  const [testDate, setTestDate] = useState(null);
  const [role, setRole] = useState(null);
  const fetchResults = async () => {
    try {
      setIsLoading(true);
      const response = await getAllResults(
        token,
        pageIndex + 1,
        pageSize,
        userId?.trim(),
        faculty?.trim(),
        role?.trim(),
        department?.trim(),
        testDate?.trim()
      );
      setData(response.data || []);
      setTotalPages(response.totalPages || 0);
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchResults();
  }, [pageIndex, pageSize, userId, faculty, role, department, testDate]);

  const handlePageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPageIndex(0);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Sn',
        accessor: (row, i) => i + 1,
        Cell: ({ value }) => <div className="w-20 text-center">{value}</div>,
      },
      {
        Header: 'Applicant Req No',
        accessor: 'userId',
        Cell: ({ value }) => <div className="w-60">{value}</div>,
      },
      {
        Header: 'Applicant Name',
        accessor: (row) =>
          `${row?.user?.firstName} ${row?.user?.middleName ?? ''} ${
            row?.user?.surName
          }`,
        Cell: ({ value }) => <div className="w-72">{value}</div>,
      },
      {
        Header: 'Score',
        accessor: 'score',
        Cell: ({ value }) => <div className="w-40">{value}</div>,
      },
      {
        Header: 'Faculty',
        accessor: 'faculty',
        Cell: ({ value }) => <div className="w-72">{value}</div>,
      },
      {
        Header: 'Department',
        accessor: 'department',
        Cell: ({ value }) => <div className="w-32">{value}</div>,
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value }) => <div className="w-32">{value}</div>,
      },
      {
        Header: 'Test Date',
        accessor: 'testDate',
        Cell: ({ value }) => (
          <div className="w-40">
            <FormatDate value={value} />
          </div>
        ),
      },
      // {
      //   Header: 'Action',
      //   Cell: ({ row }) => (
      //     <TableOption>
      //       <ul className="flex flex-col space-y-2">
      //         <li className="block p-2 text-sm text-primary text-left">
      //           <button onClick={() => openModal(row.original.userId)}>
      //             Update Password
      //           </button>
      //         </li>
      //       </ul>
      //     </TableOption>
      //   ),
      // },
    ],
    []
  );
  const exportToExcel = async () => {
    try {
      setIsLoading(true);

      let allData = [];
      let currentPage = 1;
      let totalPages = 1;

      do {
        // Fetch data for the current page
        const response = await exportToExcelApi(token, currentPage);

        if (
          response &&
          response.status === 'success' &&
          response.data.length > 0
        ) {
          allData = [...allData, ...response.data]; // Aggregate data
          totalPages = response.totalPages || 1; // Get the total number of pages from the API response
        } else {
          totalPages = 0; // Break loop if no more pages
        }

        currentPage++;
      } while (currentPage <= totalPages);

      if (allData.length > 0) {
        // Map all API data to Excel-friendly format
        const mappedData = allData.map((item) => ({
          CreatedAt: new Date(item.createdAt).toLocaleString(),
          role: item.role || 'Not Provided',
          score: item.score || 'Not Provided',
          Department: item.department || 'Not Assigned',
          Faculty: item.faculty || 'Not Assigned',
          UserID: item.userId || 'N/A',
        }));

        // Create a new workbook and worksheet
        const worksheet = XLSX.utils.json_to_sheet(mappedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Cbt-Results');

        // Generate Excel file and trigger download
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        const blob = new Blob([excelBuffer], {
          type: 'application/octet-stream',
        });
        saveAs(blob, 'applicants_export.xlsx');

        toast.success('Export successful!');
      } else {
        toast.error('No data available to export.');
      }
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      toast.error('Failed to export data.');
    } finally {
      setIsLoading(false);
    }
  };

  const nav = [
    {
      label: 'Export All',
      icon: <FileDownloadIcon fontSize="small" className="text-primary" />,
      onClick: exportToExcel,
    },
  ];
  const departmentOptions = [
    {
      value: 'Library and Information Science',
      label: 'Library and information science',
    },
    { value: 'Computer Engineering', label: 'Computer engineering' },
    { value: 'Computer Science', label: 'Computer science' },
    { value: 'Electrical Engineering', label: 'Electrical engineering' },
    { value: 'Accountancy', label: 'Accountancy' },
  ];
  return (
    <>
      <div className="flex justify-between m-8 space-x-4 items-start">
        <p className="text-primary text-2xl font-bold">
          Applicants CBT Results
        </p>
      </div>
      <div>
        <FilterSearch>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <InputField
                label="User ID"
                name="userId"
                placeholder="Enter User ID"
                value={userId}
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
              />
            </div>
            <div>
              <SelectField
                label="School"
                name="faculty"
                options={[
                  { value: 'Technology', label: 'Technology' },
                  { value: 'Management', label: 'Management' },
                  { value: 'General Studies', label: 'General Studies' },
                ]}
                value={faculty}
                onChange={(e) => {
                  setFaculty(e.target.value);
                }}
              />
            </div>
            <div>
              <SelectField
                label="Choose Department you want"
                name="department"
                options={
                  faculty === 'Management'
                    ? [{ value: 'Accountancy', label: 'Accountancy' }]
                    : departmentOptions.filter(
                        (department) => department.value !== 'Accountancy'
                      )
                }
                value={department}
                onChange={(e) => {
                  setDepartment(e.target.value);
                }}
              />
            </div>

            <div>
              <SelectField
                label="Role"
                name="role"
                options={
                  department === 'Accountancy'
                    ? [
                        { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                        { value: 'Lecturer I', label: 'Lecturer I' },
                        { value: 'Lecturer II', label: 'Lecturer II' },
                        { value: 'Lecturer III', label: 'Lecturer III' },
                      ]
                    : faculty === 'Technology' &&
                      [
                        'Library and Information Science',
                        'Electrical Engineering',
                      ].includes(department)
                    ? [
                        { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                        { value: 'Lecturer I', label: 'Lecturer I' },
                        { value: 'Lecturer II', label: 'Lecturer II' },
                        { value: 'Lecturer III', label: 'Lecturer III' },
                      ]
                    : faculty === 'Technology' &&
                      department === 'Computer Engineering'
                    ? [
                        { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                        { value: 'Lecturer I', label: 'Lecturer I' },
                        { value: 'Lecturer II', label: 'Lecturer II' },
                        {
                          value: 'Senior Instructor',
                          label: 'Senior Instructor',
                        },
                        { value: 'Technologist I', label: 'Technologist I' },
                      ]
                    : faculty === 'Technology' &&
                      department === 'Computer Science'
                    ? [
                        { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                        { value: 'Lecturer I', label: 'Lecturer I' },
                        { value: 'Lecturer II', label: 'Lecturer II' },
                        { value: 'Lecturer III', label: 'Lecturer III' },
                        {
                          value: 'Senior Instructor',
                          label: 'Senior Instructor',
                        },
                        { value: 'Technologist I', label: 'Technologist I' },
                      ]
                    : faculty === 'General Studies'
                    ? [
                        {
                          value: 'Lecturer IM',
                          label: 'Lecturer I Mechanical Engineering',
                        },
                        {
                          value: 'Lecturer IE',
                          label: 'Lecturer I Education (Psychology/Sociology)',
                        },
                        {
                          value: 'Lecturer IIE',
                          label: 'Lecturer II Entrepreneurship',
                        },
                        {
                          value: 'Lecturer IIM',
                          label: 'Lecturer II  Mathematics',
                        },
                        {
                          value: 'Lecturer IIL',
                          label: 'Lecturer II (Business Administration/Law)',
                        },
                      ]
                    : []
                }
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              />
            </div>
            <div>
              <InputField
                type="date"
                label="Test Date"
                name="testDate"
                value={testDate}
                onChange={(e) => {
                  setTestDate(e.target.value);
                }}
              />
            </div>
          </div>
        </FilterSearch>
      </div>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : error ? (
        <div className="text-red-500 text-center">Error: {error}</div>
      ) : data.length === 0 ? (
        <EmptyTable columns={columns} message="No users records found." />
      ) : (
        <ReusableTable
          columns={columns}
          data={data}
          nav={nav}
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      )}
    </>
  );
};

export default Results;
