import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteQuestion } from '../../../api/examApi';
import Button from '../../../components/button/Button';
import { toast } from 'react-toastify';
const DeleteQuestion = ({ id }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await deleteQuestion(token, id);
      if (response.success) {
        toast.success(response.message);
        window.location.href = '/app/exams';
      }
    } catch (err) {
      toast.error(err.message || 'Error deleting question');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-center p-6">
      <h2 className="text-2xl font-bold text-primary mb-4">Delete Question</h2>
      <p className="text-gray-600 mb-6">
        Are you sure you want to delete this question?
      </p>
      <div className="flex justify-center gap-4">
        <Button
          type="button"
          onClick={() => navigate('/app/exams')}
          className="bg-red-500 hover:bg-red-600"
          label="Cancel"
        />
        <Button
          type="button"
          onClick={handleDelete}
          className=""
          label={isLoading ? 'Deleting...' : 'Delete'}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default DeleteQuestion;
