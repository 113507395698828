import React, { useState } from 'react';
import QuestionCard from './components/QuestionCard';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getAllQuestions } from '../../api/examApi';
import Modal from '../../components/modal/Modal';
import DeleteQuestion from './components/DeleteQuestion';
const Exams = () => {
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState(null);
  const navigate = useNavigate();

  const handlePageChange = (newPage) => {
    if (newPage === 'next') {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchQuestions(nextPage);
    } else if (newPage === 'prev') {
      const prevPage = page - 1;
      setPage(prevPage);
      fetchQuestions(prevPage);
    } else {
      setPage(newPage);
      fetchQuestions(newPage);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);
  const openModal = (id) => {
    setId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const fetchQuestions = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await getAllQuestions(token, page, limit);
      if (response.success) {
        // console.log(response.data);
        setQuestions(response.data);
        setTotalPages(response.totalPages);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error('Error fetching questions:', err);
      toast.error(err.message || 'Error fetching questions');
    }
  };

  return (
    <div className="p-3">
      <div className="flex flex-col md:flex-row gap-4 justify-between items-center mb-6">
        <h1 className="text-2xl text-primary font-bold">Exam Questions</h1>
        <div className="flex flex-col md:flex-row gap-4">
          <Button
            label="Add Question"
            onClick={() => navigate('/app/create-question')}
            className="text-white px-4 py-2 rounded-lg"
          />
          <Button
            label="Add Bulk Questions"
            onClick={() => navigate('/app/create-bulk-questions')}
            className="text-white px-4 py-2 rounded-lg"
          />
          <Button
            label="Update Exam Details"
            onClick={() => navigate('/app/update-exam-details')}
            className="text-white px-4 py-2 rounded-lg"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {questions.map((question) => (
          <QuestionCard
            key={question._id}
            question={question.text}
            options={question.options}
            correctAnswer={question.correctAnswer}
            points={question.points}
            faculty={question.faculty}
            department={question.department}
            role={question.role}
            createdAt={question.createdAt}
            updatedAt={question.updatedAt}
            onUpdate={() => navigate(`/app/update-question/${question._id}`)}
            onDelete={() => openModal(question._id)}
          />
        ))}
        <div className="col-span-full flex justify-center gap-4 mt-4">
          <Button
            label="Previous"
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
                fetchQuestions();
              }
            }}
            disabled={page === 1}
            className="text-white px-4 py-2 rounded-lg"
          />
          <span className="self-center">Page {page}</span>
          <Button
            label="Next"
            onClick={() => {
              if (page < totalPages) {
                setPage(page + 1);
                fetchQuestions();
              }
            }}
            disabled={page === totalPages}
            className="text-white px-4 py-2 rounded-lg"
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} title="Delete Question">
        <DeleteQuestion id={id} />
      </Modal>
    </div>
  );
};

export default Exams;
