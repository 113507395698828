import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addBulkQuestion } from '../../../api/examApi';
import Button from '../../../components/button/Button';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
const BulkQuestions = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const token = localStorage.getItem('token');

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleFile = async (file) => {
    if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls')) {
      toast.error('Please upload an Excel file');
      return;
    }
    setFile(file);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      setJsonData(json);
    };
    reader.readAsBinaryString(file);
  };

  const handleSubmit = async () => {
    if (!jsonData) {
      toast.error('Please upload a file first');
      return;
    }

    try {
      setIsLoading(true);

      // Convert options to arrays
      const transformedData = jsonData.map((item) => ({
        ...item,
        options: item.Options.split(',').map((option) => option.trim()), // Transform options string to an array
        text: item.Text, // Ensure mapping for fields
        correctAnswer: item['Correct Answer'],
        faculty: item.Faculty,
        role: item.Role,
        department: item.Department,
        points: item.Points,
      }));

      const response = await addBulkQuestion(token, transformedData);

      if (response.success) {
        toast.success(response.message);
        navigate('/app/exams');
      }
    } catch (err) {
      toast.error(err.message || 'Error uploading questions');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-6">
        <h1 className="text-3xl font-bold text-primary mb-8 text-center">
          Bulk Upload Questions
        </h1>

        <div
          className={`relative border-2 border-dashed rounded-lg p-8 text-center ${
            dragActive ? 'border-primary bg-primary/10' : 'border-gray-300'
          }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type="file"
            accept=".xlsx,.xls"
            onChange={handleChange}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />

          <div className="space-y-4">
            <i className="fas fa-file-excel text-4xl text-gray-400"></i>
            <p className="text-gray-600">
              Drag and drop your Excel file here, or click to select
            </p>
            {file && (
              <p className="text-sm text-primary">Selected: {file.name}</p>
            )}
          </div>
        </div>

        <div className="mt-6 text-center">
          <Button
            type="button"
            onClick={handleSubmit}
            className="w-full sm:w-auto"
            label={isLoading ? 'Uploading...' : 'Upload Questions'}
            disabled={isLoading || !jsonData}
          />
        </div>
      </div>
    </div>
  );
};

export default BulkQuestions;
