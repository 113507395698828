import React from 'react';
import PropTypes from 'prop-types';

const QuestionCard = ({
  question,
  options,
  correctAnswer,
  points,
  faculty,
  department,
  role,
  createdAt,
  updatedAt,
  onUpdate,
  onDelete,
}) => {
  return (
    <div className="bg-gray-50 border border-gray-200 rounded-lg shadow-md p-6 mb-4">
      <div className="mb-4">
        <h3 className="font-semibold text-lg mb-2">Question:</h3>
        <p className="text-gray-700">{question}</p>
      </div>

      <div className="mb-4">
        <h4 className="font-medium mb-2">Options:</h4>
        <ul className="list-disc pl-6">
          {options.map((option, index) => (
            <li
              key={index}
              className={`${
                option === correctAnswer
                  ? 'text-green-600 font-medium'
                  : 'text-gray-600'
              }`}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>

      <div className="grid grid-cols-2 gap-4 text-sm">
        <div>
          <p className="font-medium">Correct Answer:</p>
          <p className="text-green-600">{correctAnswer}</p>
        </div>
        <div>
          <p className="font-medium">Points:</p>
          <p>{points}</p>
        </div>
        <div>
          <p className="font-medium">Faculty:</p>
          <p>{faculty}</p>
        </div>
        <div>
          <p className="font-medium">Department:</p>
          <p>{department}</p>
        </div>
        <div>
          <p className="font-medium">Role:</p>
          <p>{role}</p>
        </div>
        <div className="flex gap-4 mt-4">
          <button
            onClick={onUpdate}
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            {/* <span className="ml-2">Update</span> */}
          </button>
          <button
            onClick={onDelete}
            className="flex items-center text-red-600 hover:text-red-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            {/* <span className="ml-2">Delete</span> */}
          </button>
        </div>
      </div>
    </div>
  );
};

QuestionCard.propTypes = {
  question: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  correctAnswer: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  faculty: PropTypes.string.isRequired,
  department: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default QuestionCard;
