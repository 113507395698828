import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllQuestions, updateQuestion } from '../../../api/examApi';
import InputField from '../../../components/control/InputField';
import SelectField from '../../../components/control/SelectField';
import Button from '../../../components/button/Button';
import TextAreaField from '../../../components/control/TextAreaField';
import { toast } from 'react-toastify';
const UpdateQuestion = () => {
  const navigate = useNavigate();
  //   const { state } = useAuth();
  const token = localStorage.getItem('token');
  const { id } = useParams();

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await getAllQuestions(token, 1, 1, id);
        if (response.success && response.data.length > 0) {
          const questionData = response.data[0];

          setFormData({
            text: questionData.text,
            options: questionData.options,
            correctAnswer: questionData.correctAnswer,
            faculty: questionData.faculty,
            department: questionData.department,
            role: questionData.role,
            points: questionData.points,
          });
        } else {
          toast.error('Question not found');
          navigate('/app/exams');
        }
      } catch (err) {
        console.error('Error fetching question:', err);
        toast.error(err.message || 'Error fetching question');
        navigate('/app/exams');
      }
    };

    if (id) {
      fetchQuestion();
    }
  }, [id, token, navigate]);
  const [formData, setFormData] = useState({
    text: '',
    options: ['', '', '', ''],
    correctAnswer: '',
    faculty: '',
    department: '',
    role: '',
    points: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await updateQuestion(token, formData, id);
      if (response.success) {
        toast.success(response.message);
        navigate('/app/exams');
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
      if (err.status === 400 && err.success === false) {
        toast.error(err.error[0] || err.data.error[0]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('handleChange values:', { name, value });
    setFormData((prev) => {
      const newState = {
        ...prev,
        [name]: value,
      };
      console.log('Updated form data:', newState);
      return newState;
    });
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...formData.options];
    newOptions[index] = value;
    console.log('Selected option:', { index, value, newOptions });
    setFormData((prev) => ({
      ...prev,
      options: newOptions,
    }));
  };
  const departmentOptions = [
    {
      value: 'Library and Information Science',
      label: 'Library and information science',
    },
    { value: 'Computer Engineering', label: 'Computer engineering' },
    { value: 'Computer Science', label: 'Computer science' },
    { value: 'Electrical Engineering', label: 'Electrical engineering' },
    { value: 'Accountancy', label: 'Accountancy' },
  ];
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-primary mb-8 text-center">
        Update Question
      </h1>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-2xl mx-auto space-y-6 px-4 sm:px-6 md:px-8"
      >
        <SelectField
          label="School"
          name="faculty"
          options={[
            { value: 'Technology', label: 'Technology' },
            { value: 'Management', label: 'Management' },
            { value: 'General Studies', label: 'General Studies' },
          ]}
          value={formData.faculty}
          onChange={handleChange}
        />

        <SelectField
          label="Choose Department you want"
          name="department"
          options={
            formData.faculty === 'Management'
              ? [{ value: 'Accountancy', label: 'Accountancy' }]
              : departmentOptions.filter(
                  (department) => department.value !== 'Accountancy'
                )
          }
          value={formData.department}
          onChange={handleChange}
        />

        <SelectField
          label="Role"
          name="role"
          options={
            formData.department === 'Accountancy'
              ? [
                  { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                  { value: 'Lecturer I', label: 'Lecturer I' },
                  { value: 'Lecturer II', label: 'Lecturer II' },
                  { value: 'Lecturer III', label: 'Lecturer III' },
                ]
              : formData.faculty === 'Technology' &&
                [
                  'Library and Information Science',
                  'Electrical Engineering',
                ].includes(formData.department)
              ? [
                  { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                  { value: 'Lecturer I', label: 'Lecturer I' },
                  { value: 'Lecturer II', label: 'Lecturer II' },
                  { value: 'Lecturer III', label: 'Lecturer III' },
                ]
              : formData.faculty === 'Technology' &&
                formData.department === 'Computer Engineering'
              ? [
                  { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                  { value: 'Lecturer I', label: 'Lecturer I' },
                  { value: 'Lecturer II', label: 'Lecturer II' },
                  {
                    value: 'Senior Instructor',
                    label: 'Senior Instructor',
                  },
                  { value: 'Technologist I', label: 'Technologist I' },
                ]
              : formData.faculty === 'Technology' &&
                formData.department === 'Computer Science'
              ? [
                  { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                  { value: 'Lecturer I', label: 'Lecturer I' },
                  { value: 'Lecturer II', label: 'Lecturer II' },
                  { value: 'Lecturer III', label: 'Lecturer III' },
                  {
                    value: 'Senior Instructor',
                    label: 'Senior Instructor',
                  },
                  { value: 'Technologist I', label: 'Technologist I' },
                ]
              : formData.faculty === 'General Studies'
              ? [
                  {
                    value: 'Lecturer IM',
                    label: 'Lecturer I Mechanical Engineering',
                  },
                  {
                    value: 'Lecturer IE',
                    label: 'Lecturer I Education (Psychology/Sociology)',
                  },
                  {
                    value: 'Lecturer IIE',
                    label: 'Lecturer II Entrepreneurship',
                  },
                  {
                    value: 'Lecturer IIM',
                    label: 'Lecturer II  Mathematics',
                  },
                  {
                    value: 'Lecturer IIL',
                    label: 'Lecturer II (Business Administration/Law)',
                  },
                ]
              : []
          }
          value={formData.role}
          onChange={handleChange}
        />

        <TextAreaField
          label="Question"
          name="text"
          value={formData.text}
          onChange={handleChange}
          rows={4}
          className="w-full border-gray-300"
        />

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {formData.options.map((_, index) => (
            <InputField
              key={index}
              label={`Option ${index + 1}`}
              value={formData.options[index]}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              className="w-full"
            />
          ))}
        </div>

        <SelectField
          label="Correct Answer"
          name="correctAnswer"
          options={formData.options.map((option, index) => ({
            value: option,
            label: `Option ${index + 1}: ${option}`,
          }))}
          value={formData.correctAnswer}
          onChange={handleChange}
        />

        <InputField
          label="Points"
          name="points"
          type="number"
          value={formData.points}
          onChange={handleChange}
          className="w-full"
        />

        <div className="flex justify-center w-full">
          <Button
            label={isLoading ? 'Submitting...' : 'Submit'}
            type="submit"
            className="w-full sm:w-1/2"
            disabled={isLoading}
          />
        </div>

        {error && <div className="text-red-500 text-center">{error}</div>}
      </form>
    </div>
  );
};

export default UpdateQuestion;
