import React from 'react';
import PropTypes from 'prop-types';

// Define the format function outside the component for reusability
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';

  const date = new Date(dateString);

  // Convert to Nigeria time zone (WAT: UTC+1)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Africa/Lagos', // Nigeria time zone
    timeZoneName: 'short',
  };

  return date.toLocaleString(undefined, options);
};

const FormatDate = ({ value }) => {
  return <div>{value ? formatDate(value) : 'N/A'}</div>;
};

FormatDate.propTypes = {
  value: PropTypes.string,
};

export default FormatDate;
