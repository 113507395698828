import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateExamDetails } from '../../../api/examApi';
import InputField from '../../../components/control/InputField';
import SelectField from '../../../components/control/SelectField';
import Button from '../../../components/button/Button';
import TextAreaField from '../../../components/control/TextAreaField';
import { toast } from 'react-toastify';
const UpdateExamDetails = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const departmentOptions = [
    {
      value: 'Library and Information Science',
      label: 'Library and information science',
    },
    { value: 'Computer Engineering', label: 'Computer engineering' },
    { value: 'Computer Science', label: 'Computer science' },
    { value: 'Electrical Engineering', label: 'Electrical engineering' },
    { value: 'Accountancy', label: 'Accountancy' },
  ];

  const [formData, setFormData] = useState({
    faculty: '',
    department: '',
    role: '',
    examVenue: '',
  });
  const [examDate, setExamDate] = useState('');
  const [examTime, setExamTime] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setError(null);

      const examData = {
        ...formData,
        examDate,
        examTime,
      };

      const response = await updateExamDetails(token, examData);
      if (response.success) {
        toast.success('Exam details updated successfully');
        navigate('/app/exams');
      }
    } catch (err) {
      setError(err.message || 'An error occurred while updating exam details');
      toast.error(
        err.message || 'An error occurred while updating exam details'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-primary mb-8 text-center">
        Update Exam Details
      </h1>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-2xl mx-auto space-y-6 px-4 sm:px-6 md:px-8"
      >
        <SelectField
          label="School"
          name="faculty"
          options={[
            { value: 'Technology', label: 'Technology' },
            { value: 'Management', label: 'Management' },
            { value: 'General Studies', label: 'General Studies' },
          ]}
          value={formData.faculty}
          onChange={handleChange}
        />

        <SelectField
          label="Choose Department you want"
          name="department"
          options={
            formData.faculty === 'Management'
              ? [{ value: 'Accountancy', label: 'Accountancy' }]
              : departmentOptions.filter(
                  (department) => department.value !== 'Accountancy'
                )
          }
          value={formData.department}
          onChange={handleChange}
        />

        <SelectField
          label="Role"
          name="role"
          options={
            formData.department === 'Accountancy'
              ? [
                  { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                  { value: 'Lecturer I', label: 'Lecturer I' },
                  { value: 'Lecturer II', label: 'Lecturer II' },
                  { value: 'Lecturer III', label: 'Lecturer III' },
                ]
              : formData.faculty === 'Technology' &&
                [
                  'Library and Information Science',
                  'Electrical Engineering',
                ].includes(formData.department)
              ? [
                  { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                  { value: 'Lecturer I', label: 'Lecturer I' },
                  { value: 'Lecturer II', label: 'Lecturer II' },
                  { value: 'Lecturer III', label: 'Lecturer III' },
                ]
              : formData.faculty === 'Technology' &&
                formData.department === 'Computer Engineering'
              ? [
                  { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                  { value: 'Lecturer I', label: 'Lecturer I' },
                  { value: 'Lecturer II', label: 'Lecturer II' },
                  {
                    value: 'Senior Instructor',
                    label: 'Senior Instructor',
                  },
                  { value: 'Technologist I', label: 'Technologist I' },
                ]
              : formData.faculty === 'Technology' &&
                formData.department === 'Computer Science'
              ? [
                  { value: 'Senior Lecturer', label: 'Senior Lecturer' },
                  { value: 'Lecturer I', label: 'Lecturer I' },
                  { value: 'Lecturer II', label: 'Lecturer II' },
                  { value: 'Lecturer III', label: 'Lecturer III' },
                  {
                    value: 'Senior Instructor',
                    label: 'Senior Instructor',
                  },
                  { value: 'Technologist I', label: 'Technologist I' },
                ]
              : formData.faculty === 'General Studies'
              ? [
                  {
                    value: 'Lecturer IM',
                    label: 'Lecturer I Mechanical Engineering',
                  },
                  {
                    value: 'Lecturer IE',
                    label: 'Lecturer I Education (Psychology/Sociology)',
                  },
                  {
                    value: 'Lecturer IIE',
                    label: 'Lecturer II Entrepreneurship',
                  },
                  {
                    value: 'Lecturer IIM',
                    label: 'Lecturer II  Mathematics',
                  },
                  {
                    value: 'Lecturer IIL',
                    label: 'Lecturer II (Business Administration/Law)',
                  },
                ]
              : []
          }
          value={formData.role}
          onChange={handleChange}
        />

        <TextAreaField
          label="Exam Venue"
          name="examVenue"
          value={formData.examVenue}
          onChange={handleChange}
          rows={4}
          className="w-full border-gray-300"
        />

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <InputField
              type="date"
              label="Exam Date"
              name="examDate"
              value={examDate}
              onChange={(e) => {
                setExamDate(e.target.value);
              }}
            />
          </div>
          <div>
            <InputField
              type="time"
              label="Exam Time"
              name="examTime"
              value={examTime}
              onChange={(e) => {
                setExamTime(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="flex justify-center w-full">
          <Button
            label={isLoading ? 'Submitting...' : 'Submit'}
            type="submit"
            className="w-full sm:w-1/2"
            disabled={isLoading}
          />
        </div>

        {error && <div className="text-red-500 text-center">{error}</div>}
      </form>
    </div>
  );
};

export default UpdateExamDetails;
